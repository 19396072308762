<template>
  <div v-loading="loading">
    <div v-if="isEdit">
      <div class="main-container mt-1">
        <div class="title-class">
          <h3>
            {{ this.form.name ? this.form.name : "" }}
            <i class="el-icon-edit" @click="nameDialog = true"></i>
          </h3>
        </div>
        <div>
          <div class="center-container">
            <div class="step-box">
              <div class="d-flex">
                <el-button
                  size="mini"
                  icon="el-icon-back"
                  @click="goBack"
                  type="text"
                  style="margin-top: -10px; margin-right: 5px"
                  >Back</el-button
                >
                <p style="color: #303133; margin-top: 10px">
                  When do you want to execute
                </p>
                <div class="track-bar" style="margin-left: auto">
                  <div class="status-circle">
                    <p>When</p>
                  </div>
                </div>
              </div>
              Execute on
              <el-select
                v-model="form.when"
                placeholder="When point"
                style="width: 100%"
              >
                <el-option value="ADDED" label="Added"> </el-option>
                <el-option value="NOTIFICATION" label="Notification">
                </el-option>
                <el-option value="DELETED" label="Deleted"> </el-option>
                <el-option value="TRANSACTION" label="Record Payment">
                </el-option>
                <el-option value="AUTOMATION" label="Recurring"> </el-option>
                <el-option value="FILE_ADDED" label="File added"> </el-option>
                <el-option
                  value="DATE_TIME"
                  label="Date/Time Field"
                ></el-option>
                <el-option-group label="Update">
                  <el-option
                    v-for="(temp, i) in currentEntityTemplates"
                    :key="i"
                    :label="'Template-' + temp.name"
                    :value="'template/' + temp._id"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
              <div v-if="form.when == 'DATE_TIME'" style="margin-top: 10px">
                <div style="margin: 5px 0 0 0">
                  <el-radio
                    v-if="!form.is_global_action"
                    style="margin: 0 10px 5px 0px"
                    v-model="schedule_type"
                    label="date_field"
                    >Select Date Field</el-radio
                  >
                  <el-radio
                    style="margin: 0 10px 5px 20px"
                    v-model="schedule_type"
                    label="Select_manually"
                    >Select Manually
                  </el-radio>
                  <br />
                </div>
                <div
                  v-if="
                    form.when == 'DATE_TIME' && schedule_type == 'date_field'
                  "
                  style="margin: 5px 0 0 0"
                >
                  Based on which Date/Time Field
                  <el-select
                    style="width: 100%"
                    v-model="schedule_data_id"
                    @change="setScheduleData(schedule_data_id)"
                  >
                    <el-option
                      v-for="field in date_time_fields"
                      :key="field.id"
                      :value="field.id"
                      :label="field.label"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="form-row">
                  <div>
                    <label for="time_zone" style="margin: 0 10px 0 0"
                      >Time Zone</label
                    >
                    <el-select
                      v-model="selected_time_zone"
                      placeholder="Select Timezone"
                      :filterable="true"
                      size="mini"
                      id="time_zone"
                    >
                      <el-option
                        v-for="(timezone, index) of time_zone"
                        :key="index"
                        :label="timezone"
                        :value="timezone"
                      ></el-option>
                    </el-select>
                  </div>
                  <div
                    v-if="
                      schedule_type == 'date_field' &&
                      schedule_data.input_type == 'DATE_TIME'
                    "
                  >
                    <label for="condition">condition</label>
                    <el-select
                      v-model="selected_period"
                      id="condition"
                      size="mini"
                    >
                      <el-option
                        v-for="(item, i) in scheduleExecutionPeriodOptions"
                        :key="item.value + i"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div
                  style="margin-top: 10px"
                  v-if="schedule_type == 'date_field'"
                >
                  <div
                    style="margin-top: 10px"
                    v-if="
                      schedule_data &&
                      (schedule_data.input_type == 'DATE' ||
                        schedule_data.input_type == 'DATE_TIME')
                    "
                  >
                    Executed Date
                    <el-select
                      v-model="schedule_settings.executed_on_date"
                      style="max-width: 110px; margin: 0 0px 0 20px"
                      size="mini"
                    >
                      <el-option
                        v-for="(item, i) in ['On', 'Before', 'After']"
                        :key="item + i"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                    <span
                      style="margin-left: 5px"
                      v-if="schedule_settings.executed_on_date == 'On'"
                    >
                      same date as <b>{{ schedule_data.label }}</b> field</span
                    >
                    <el-input-number
                      v-if="schedule_settings.executed_on_date != 'On'"
                      v-model="schedule_settings.executed_on_date_value"
                      style="max-width: 110px; margin-left: 10px"
                      type="number"
                      size="mini"
                      :min="1"
                      :max="30"
                    >
                    </el-input-number>
                    <el-select
                      v-if="schedule_settings.executed_on_date != 'On'"
                      v-model="schedule_settings.executed_on_date_period"
                      style="max-width: 110px; margin: 0 10px 0 10px"
                      :disabled="!schedule_settings.executed_on_date_value"
                      size="mini"
                    >
                      <el-option
                        v-for="(item, i) in [
                          'Day(s)',
                          'Week(s)',
                          'Month(s)',
                          'Year(s)',
                        ]"
                        :key="item + i"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <!-- <div
                    style="margin-top: 10px"
                    v-if="schedule_data && schedule_data.input_type == 'DATE'"
                  >
                    Execution Time
                    <el-time-select
                      :picker-options="{
                        start: '00:00',
                        step: '00:10',
                        end: '23:50',
                      }"
                      placeholder="On"
                      v-model="schedule_settings.execution_time"
                      style="max-width: 145px; margin: 0 10px 0 15px"
                      :clearable="false"
                      @change="schedule_settings.custom_execution_time = ''"
                      size="mini"
                    >
                    </el-time-select>
                  </div> -->
                  <div
                    style="margin-top: 10px"
                    v-if="
                      schedule_data &&
                      schedule_data.input_type == 'DATE_TIME' &&
                      (selected_period == 'full_match' ||
                        selected_period == 'time_match')
                    "
                  >
                    Execution Time
                    <el-select
                      v-model="schedule_settings.executed_on_time"
                      style="max-width: 110px; margin: 0 0px 0 15px"
                      size="mini"
                    >
                      <el-option
                        v-for="(item, i) in ['On', 'Before', 'After']"
                        :key="item + i"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                    <el-input
                      v-if="schedule_settings.executed_on_time != 'On'"
                      v-model="schedule_settings.executed_on_time_value"
                      style="max-width: 110px; margin-left: 10px"
                      type="number"
                      size="mini"
                    >
                    </el-input>
                    <el-select
                      v-if="schedule_settings.executed_on_time != 'On'"
                      v-model="schedule_settings.executed_on_time_period"
                      style="max-width: 110px; margin: 0 10px 0 10px"
                      :disabled="!schedule_settings.executed_on_time_value"
                      size="mini"
                    >
                      <el-option
                        v-for="(item, i) in ['Minute(s)', 'Hour(s)']"
                        :key="item + i"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                    <span
                      style="margin-left: 5px"
                      v-if="schedule_settings.executed_on_time == 'On'"
                    >
                      same time as <b>{{ schedule_data.label }}</b> field</span
                    >
                  </div>
                  <div
                    style="margin-top: 10px"
                    v-if="
                      schedule_data &&
                      (schedule_data.input_type == 'DATE' ||
                        schedule_data.input_type == 'DATE_TIME')
                    "
                  >
                    Recurring On
                    <el-select
                      v-model="schedule_settings.recurring_execution_type"
                      style="max-width: 110px; margin: 0 10px 0 30px"
                      size="mini"
                    >
                      <el-option
                        v-for="(item, i) in recurrence_type"
                        :key="item.value + i"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-input-number
                      v-if="
                        schedule_settings.recurring_execution_type != 'once'
                      "
                      v-model="schedule_settings.recurring_execution_value"
                      style="max-width: 110px; margin-left: 0px"
                      type="number"
                      size="mini"
                      :min="1"
                      :max="30"
                    >
                    </el-input-number>
                    <el-select
                      v-if="
                        schedule_settings.recurring_execution_type != 'once'
                      "
                      v-model="schedule_settings.recurring_period"
                      style="max-width: 110px; margin: 0 10px 0 10px"
                      :disabled="!schedule_settings.recurring_execution_value"
                      size="mini"
                    >
                      <el-option
                        v-for="(item, i) in recurring_period_types"
                        :key="item.value + i"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <!-- <el-select
                      v-model="schedule_settings.recurring_order"
                      style="max-width:110px; margin:0 10px 0 10px;"
                     >
                      <el-option
                      v-for="(item) in recurring_on_options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                        {{ item.label }}
                      </el-option>
                    </el-select> -->
                    <div
                      v-if="
                        schedule_settings.recurring_execution_type != 'once'
                      "
                      style="margin-top: 10px"
                    >
                      Till
                      <el-select
                        v-model="schedule_settings.recurring_stopping_condition"
                        style="max-width: 110px; margin: 0 10px 0 97px"
                        size="mini"
                      >
                        <el-option
                          v-for="(item, i) in ['After']"
                          :key="item + i"
                          :label="item"
                          :value="item"
                        ></el-option>
                      </el-select>
                      <el-input-number
                        v-if="
                          schedule_settings.recurring_stopping_condition ===
                          'After'
                        "
                        v-model="schedule_settings.recurring_stopping_value"
                        style="max-width: 110px; margin-left: 0px"
                        type="number"
                        size="mini"
                        :min="1"
                        :max="20"
                      ></el-input-number>
                      <span
                        style="margin-left: 15px"
                        v-if="
                          schedule_settings.recurring_stopping_condition ===
                          'After'
                        "
                        >occurrences</span
                      >
                      <!-- <el-date-picker
                            v-if="schedule_settings.recurring_stopping_condition === 'Date'"
                            v-model="schedule_settings.recurring_stopping_date"
                            type="date"
                            style="max-width: 140px; margin-left: 10px;"
                          ></el-date-picker> -->
                    </div>
                  </div>
                </div>
                <div style="margin-top: 10px" v-else>
                  <el-row>
                    <el-col style="margin: 5px 0 0 0" :span="24">
                      <el-card class="box-card">
                        <div slot="header" class="clearfix">
                          <span>
                            <el-checkbox
                              :indeterminateminutes="isminutesIndeterminate"
                              v-model="manual_schedule_settings.checkAllminutes"
                              @change="handleCheckMinutesChange"
                            >
                              Minutes</el-checkbox
                            ></span
                          >
                        </div>
                        <div class="text item">
                          <el-checkbox-group
                            v-model="manual_schedule_settings.minutes"
                            @change="handleCheckedMinuteChange"
                            class="checkbox-group"
                          >
                            <el-checkbox
                              v-for="minute in minutes"
                              :label="minute"
                              :key="minute"
                              class="uniform-checkbox"
                              >{{ minute }}</el-checkbox
                            >
                          </el-checkbox-group>
                        </div>
                      </el-card>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col style="margin: 5px 0 0 0" :span="24">
                      <el-card class="box-card">
                        <div slot="header" class="clearfix">
                          <span>
                            <el-checkbox
                              :indeterminateminuteshours="ishoursIndeterminate"
                              v-model="manual_schedule_settings.checkAllhours"
                              @change="handleCheckHoursChange"
                            >
                              Hours</el-checkbox
                            ></span
                          >
                        </div>
                        <div class="text item">
                          <el-checkbox-group
                            v-model="manual_schedule_settings.hours"
                            @change="handleCheckedHourChange"
                            class="checkbox-group"
                          >
                            <el-checkbox
                              v-for="hour in hours"
                              :label="hour"
                              :key="hour"
                              class="uniform-checkbox"
                              >{{ hour }}</el-checkbox
                            >
                          </el-checkbox-group>
                        </div>
                      </el-card>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col style="margin: 5px 0 0 0" :span="24">
                      <el-card class="box-card">
                        <div slot="header" class="clearfix">
                          <span>
                            <el-checkbox
                              :indeterminateminutesdays="isdaysIndeterminate"
                              v-model="manual_schedule_settings.checkAlldays"
                              @change="handleCheckDaysChange"
                            >
                              Days</el-checkbox
                            ></span
                          >
                        </div>
                        <div class="text item">
                          <el-checkbox-group
                            v-model="manual_schedule_settings.days"
                            @change="handleCheckedDayChange"
                            class="checkbox-group"
                          >
                            <el-checkbox
                              v-for="day in days"
                              :label="day"
                              :key="day"
                              class="uniform-checkbox"
                              >{{ day }}</el-checkbox
                            >
                          </el-checkbox-group>
                        </div>
                      </el-card>
                    </el-col>
                  </el-row>
                  <el-row style="margin: 5px 0 0 0">
                    <el-col style="margin: 5px 0 0 25px" :span="7">
                      <el-card class="box-card">
                        <div slot="header" class="clearfix">
                          <span>
                            <el-checkbox
                              :indeterminateminutesweeks="isweeksIndeterminate"
                              v-model="manual_schedule_settings.checkAllweeks"
                              @change="handleCheckWeeksChange"
                            >
                              Days of Week</el-checkbox
                            ></span
                          >
                        </div>
                        <div class="text item">
                          <el-checkbox-group
                            v-model="manual_schedule_settings.days_of_week"
                            @change="handleCheckedWeekChange"
                          >
                            <el-checkbox
                              v-for="weekDay in weeks"
                              :label="weekDay"
                              :key="weekDay"
                              >{{ weekDay }}</el-checkbox
                            >
                          </el-checkbox-group>
                        </div>
                      </el-card>
                    </el-col>
                    <el-col style="margin: 5px" :span="7">
                      <el-card class="box-card">
                        <div slot="header" class="clearfix">
                          <span>
                            <el-checkbox
                              :indeterminateminutesmonths="
                                ismonthsIndeterminate
                              "
                              v-model="manual_schedule_settings.checkAllmonths"
                              @change="handleCheckMonthsChange"
                            >
                              Months</el-checkbox
                            ></span
                          >
                        </div>
                        <div class="text item">
                          <el-checkbox-group
                            v-model="manual_schedule_settings.months"
                            @change="handleCheckedMonthChange"
                          >
                            <el-checkbox
                              v-for="month in months"
                              :label="month"
                              :key="month"
                              >{{ month }}</el-checkbox
                            >
                          </el-checkbox-group>
                        </div>
                      </el-card>
                    </el-col>
                    <el-col style="margin: 5px" :span="7">
                      <el-card class="box-card">
                        <div slot="header" class="clearfix">
                          <span>
                            <el-checkbox
                              :indeterminateminutesyears="isyearsIndeterminate"
                              v-model="manual_schedule_settings.checkAllyears"
                              @change="handleCheckYearsChange"
                            >
                              Years</el-checkbox
                            ></span
                          >
                        </div>
                        <div class="text item">
                          <el-checkbox-group
                            v-model="manual_schedule_settings.years"
                            @change="handleCheckedYearChange"
                          >
                            <el-checkbox
                              v-for="year in years"
                              :label="year"
                              :key="year"
                              >{{ year }}</el-checkbox
                            >
                          </el-checkbox-group>
                        </div>
                      </el-card>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div
                v-else-if="form.when == 'AUTOMATION'"
                style="margin-top: 10px"
              >
                <div>
                  <span style="margin-left: 20px"> For Every </span>
                  <el-input-number
                    v-model="recurrence_settings.recurring_execution_value"
                    style="max-width: 150px; margin-left: 93px"
                    type="number"
                    size="mini"
                    :min="1"
                    :max="30"
                  >
                  </el-input-number>
                  <el-select
                    v-model="recurrence_settings.recurring_period"
                    style="max-width: 150px; margin-left: 27px"
                    size="mini"
                  >
                    <el-option
                      v-for="(item, i) in recurring_period_types"
                      :key="item.value + i"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div style="margin-top: 10px">
                  <span style="margin-left: 20px"> Start Time </span>
                  <el-date-picker
                    style="margin-left: 78px; width: 150px"
                    v-model="recurrence_settings.execution_date"
                    type="date"
                    placeholder="Pick a day"
                    size="mini"
                    :disabled="recurrence_settings.recurring_period == 'days'"
                    format="MM-dd-yyyy"
                    :picker-options="pickerOptions"
                  >
                  </el-date-picker>
                  <el-time-select
                    style="margin-left: 15px; width: 150px"
                    v-model="recurrence_settings.execution_time"
                    :picker-options="{
                      start: '00:00',
                      step: '00:15',
                      end: '23:45',
                    }"
                    size="mini"
                    placeholder="Select time"
                  >
                  </el-time-select>
                </div>
                <div style="margin-top: 10px">
                  <span style="margin-left: 20px"> Time Zone </span>
                  <el-select
                    v-model="recurrence_settings.zone"
                    style="margin-left: 78px; width: 150px"
                    placeholder="Select Timezone"
                    :filterable="true"
                    size="mini"
                    id="time_zone"
                  >
                    <el-option
                      v-for="(timezone, index) of time_zone"
                      :key="index"
                      :label="timezone"
                      :value="timezone"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div v-if="otherAddActions.length">
                <el-checkbox class="mt-1" v-model="form.establishRelationship"
                  >Establish Relationships</el-checkbox
                >
                <el-tooltip
                  class="ml-1"
                  content="This will enable the feature to establish the external relationships between the entities you are adding through this action."
                  ><i class="el-icon-info"></i
                ></el-tooltip>
              </div>
              <div class="mt-2" v-if="true">
                <el-checkbox
                v-model="form.is_global_action"
                @change="handleGlobalActionChange">
                Is Entity Level Action
                </el-checkbox>
              </div>
            </div>
          </div>
          <div class="center-container">
            <el-button
              v-if="!form.then || !form.then.length"
              class="left-add"
              icon="el-icon-plus"
              circle
              @click="addNewAction('then', form)"
              style="color: #409eff; font-size: 12px"
              size="mini"
            ></el-button>
          </div>
          <div style="width: 100% !important" v-if="!deleteRefresh">
            <EntityConditionalBlocks
              :step="form"
              :listType="'then'"
              :entityAllFilters="entityAllFilters"
              :showTopAdd="true"
              :currentEntity="currentEntity"
              :allEntities="allEntities"
              :allTemplates="allTemplates"
              :allTemplateFieldMap="allTemplateFieldMap"
              :existedDataConditionMap="existedDataConditionMap"
              :form="form"
              :allEntityFilters="allEntityFilters"
              :relationshipsData="relationshipsData"
              :mainAction="form.when"
              :allFormbuilders="allFormbuilders"
              :entityRootFolders="entityRootFolders"
              :company_users="company_users"
              :allContacts="allContacts"
            >
            </EntityConditionalBlocks>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <el-row class="mb-1">
        <div class="float-right">
          <el-input
            class="search-input"
            placeholder="Search"
            v-model="search_string"
            clearable
            :style="getIsMobile ? 'width:130px' : 'width:170px;'"
          ></el-input>
          <el-select
            v-model="selectedType"
            placeholder="Select Type"
            clearable
            style="margin-right: 10px; width: 170px"
            @change="fetchEntityActions()"
          >
            <el-option
              v-for="(item, index) of statusTypes"
              :key="index"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-button
            type="primary"
            @click="addNew(null)"
            icon="el-icon-circle-plus-outline"
          >
            Add new action
          </el-button>
        </div>
      </el-row>
      <el-row class="vue-data-table-default">
        <data-tables-server
          :total="total"
          :data="entityActions"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationProps"
          @query-change="loadData"
          @sort-change="sortChange"
        >
          <el-table-column label="Name" prop="name" width="200">
            <template slot-scope="scope">
              <div class="d-flex activestatus">
                <div v-if="scope.row.name">
                  <el-tooltip :content="scope.row.name">
                    <span>
                      {{ scope.row.name | truncate(15, "...") }}
                    </span>
                  </el-tooltip>
                </div>
                <div v-else>
                  <span>-</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Description" width="200">
            <template slot-scope="scope">
              <span style="word-break: break-word">{{
                scope.row.description
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="When" prop="when" min-width="200">
            <template slot-scope="scope">
              <div
                v-if="scope.row.when == 'ADDED' || scope.row.when == 'DELETED'"
              >
                {{ scope.row.when }}
              </div>
              <div v-else-if="scope.row.when.includes('template/')">
                {{
                  getFieldProperty(
                    (currentEntity.templates || []).map(
                      (e) => e.templateInfo
                    ) || [],
                    scope.row.when.replace("template/", ""),
                    "_id",
                    "name",
                    "Template update"
                  ) + " template update"
                }}
              </div>
              <div v-else>{{ scope.row.when }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Then" prop="then" min-width="150">
            <template slot-scope="scope">
              <div
                v-if="scope.row.then && scope.row.then.length"
                style="display: block"
              >
                <div class="d-flex">
                  {{ scope.row.then.filter((e) => e.type == "ACTION").length }}
                  Actions
                </div>
                <div class="d-flex">
                  {{
                    scope.row.then.filter((e) => e.type == "CONDITION").length
                  }}
                  Conditions
                </div>
              </div>
              <div v-else>No actions added</div>
            </template>
          </el-table-column>
          <el-table-column label="Status" prop="status" width="120" sortable>
            <template slot-scope="scope">
              <div class="d-flex activestatus">
                <div :class="scope.row.status.toLowerCase() + ' circle'"></div>
                <div>
                  <p v-if="scope.row.status == 'ACTIVE'">Active</p>
                  <p v-else-if="scope.row.status == 'INACTIVE'">Inactive</p>
                  <p v-else>Deleted</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Last Modified"
            prop="last_modified"
            sortable="custom"
            min-width="130"
          >
            <template slot-scope="scope">
              {{ scope.row.updated_at | globalDateTimeFormat }}
            </template>
          </el-table-column>
          <el-table-column
            label="Actions"
            prop="actions"
            fixed="right"
            class="action"
            min-width="100"
          >
            <template slot-scope="scope">
              <ul class="action-buttons">
                <li v-if="scope">
                  <el-button
                    plain
                    type="warning"
                    size="mini"
                    class="px-2 ml-2"
                    title="Edit"
                    @click="addNew(scope.row)"
                  >
                    <i class="el-icon-edit"></i>
                  </el-button>
                </li>
                <li>
                  <el-button
                    plain
                    type="danger"
                    size="mini"
                    class="px-2 ml-2"
                    title="Delete"
                    @click="onDelete(scope.row)"
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column fixed="right" class="action" min-width="50">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="ACTIVE"
                inactive-value="INACTIVE"
                @change="updateRow(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
        </data-tables-server>
      </el-row>
    </div>
    <dialog-component
      :title="'Action Details'"
      :visible="nameDialog"
      :containerWidth="'25%'"
      @before-close="nameActionDialog"
      class="bt"
    >
      <div class="m-tb-3">
        <span>Action Name</span>
        <el-input
          @keyup.enter.native="handleEnterKey()"
          v-model="form.name"
          placeholder="Enter Action Name"
          maxlength="50"
          show-word-limit
          class="mb-15"
        >
        </el-input>
        <span class="temp-sub-title">Action Description</span>
        <br />
        <el-input
          size="medium"
          type="textarea"
          placeholder="Action Description"
          v-model="form.description"
          :autosize="{ minRows: 2, maxRows: 20 }"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <button
          type="button"
          class="btn btn-outline-primary btn-sm"
          style="float: right; width: 100px; margin-top: 10px"
          @click="handleName"
        >
          <i class="fa fa-paper-plane-o"></i>
          Save
        </button>
      </span>
    </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EntityConditionalBlocks from "./EntityConditionalBlocks.vue";
import AutomationHelper from "@/mixins/AutomationHelper";
import { configs } from "@/assets/data/schedule_config.ts";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper";
const weeksArray = configs.weeksArray;
const monthsArray = configs.monthsArray;
const timezonesArray = configs.timezonesArray;

let minutesArray = [];
let hoursArray = [];
let daysArray = [];
let yearsArray = [];
export default {
  mixins: [AutomationHelper, TemplateBuilderHelper],
  components: { EntityConditionalBlocks },
  props: ["currentEntity", "saveClicked"],
  data() {
    return {
      loading: false,
      actions: [],
      form: {
        when: "ADDED",
        then: [
          {
            action: "other/UPDATE",
            type: "CONDITION",
            then: [],
            else: [],
            keyIndex: (Math.random() + 1).toString(36).substring(7),
            email_field: "",
            email_obj: {},
            automationConfigure: false,
          },
        ],
        otherAddActions: [],
        establishRelationship: false,
        name: "",
        description: "",
      },
      relationshipsData: [],
      entityAllFilters: [],
      allEntities: [],
      allTemplates: [],
      allTemplateFieldMap: {},
      existedDataConditionMap: {},
      mapPrimaryFieldsModal: false,
      isEdit: false,
      entityActions: [],
      total: 0,
      activeStep: 0,
      currentPage: 1,
      pageSize: 0,
      page: 1,
      order_type: "",
      order_by: "",
      allEntityFilters: {},
      selectedType: "",
      statusTypes: [
        {
          name: "Active",
          value: "ACTIVE",
        },
        {
          name: "Inactive",
          value: "INACTIVE",
        },
      ],
      recurrence_type: [
        {
          label: "Once",
          value: "once",
        },
        {
          label: "For Every",
          value: "for_every",
        },
      ],
      recurring_period_types: [
        {
          label: "Day(s)",
          value: "days",
        },
        // {
        //   label : "Week(s)",
        //   value :"weeks",
        // },
        {
          label: "Month(s)",
          value: "months",
        },
        {
          label: "Year(s)",
          value: "years",
        },
      ],
      date_time_fields: [],
      schedule_data: "",
      schedule_data_id: "",
      schedule_type: "date_field",
      schedule_settings: {
        executed_on_date: "On",
        executed_on_time: "On",
        execution_time: "05:00",
        recurring_order: "once",
        executed_on_date_value: "1",
        executed_on_date_period: "Day(s)",
        executed_on_time_value: "1",
        executed_on_time_period: "Hour(s)",
        recurring_execution_type: "once",
        recurring_execution_value: "5",
        recurring_period: "Day(s)",
        recurring_stopping_condition: "After",
        time_zone: "",
        recurring_stopping_value: "2",
        schedule_period: "month_match",
        // recurring_stopping_date:''
      },
      manual_schedule_settings: {
        minutes: [0],
        hours: [0],
        days: [1],
        months: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        days_of_week: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        years: [2024, 2025],
        checkAllminutes: false,
        checkAllhours: false,
        checkAlldays: false,
        checkAllweeks: true,
        checkAllmonths: true,
        checkAllyears: false,
        status: false,
        multiple: false,
        time_zone: "",
      },
      executed_on_options: [
        {
          label: "after 1 day ",
          value: "after_1_day",
        },
        {
          label: "after 2 days",
          value: "1_days_after",
        },
        {
          label: "after 1 week",
          value: "1_week_after",
        },
        {
          label: "after 1 month",
          value: "1_month_after",
        },
      ],
      selected_time_zone: "America/New_York",
      execution_time_options: [
        {
          label: "10 mins before",
          value: "10_mins_before",
        },
        {
          label: "15 mins before",
          value: "15_mins_before",
        },
        {
          label: "20 mins before",
          value: "20_mins_before",
        },
        {
          label: "1 hour before",
          value: "1_hour_before",
        },
        {
          label: "2 hours before",
          value: "2_hours_before",
        },
      ],
      recurring_on_options: [
        {
          label: "Once",
          value: "once",
        },
        {
          label: "Every Month",
          value: "every_month",
        },
        {
          label: "Every Year",
          value: "every_year",
        },
      ],
      isminutesIndeterminate: true,
      ishoursIndeterminate: true,
      isdaysIndeterminate: true,
      isweeksIndeterminate: true,
      ismonthsIndeterminate: true,
      isyearsIndeterminate: true,
      minutes: minutesArray,
      hours: hoursArray,
      days: daysArray,
      weeks: weeksArray,
      months: monthsArray,
      years: yearsArray,
      time_zone: timezonesArray,
      selected_period: "date_match",
      scheduleExecutionPeriodOptions: [
        { label: "Full Match", value: "full_match" },
        { label: "Date Match", value: "date_match" },
        { label: "Time Match", value: "time_match" },
        { label: "Year Match", value: "year_match" },
        { label: "Month Match", value: "month_match" },
        { label: "Month Year Match", value: "month_year_match" },
        { label: "Day Month Match", value: "day_month_match" },
      ],
      allFields: [],
      changed_status: "",
      nameDialog: false,
      search_string: "",
      allFormbuilders: [],
      entityRootFolders: [],
      company_users: [],
      allContacts: [],
      otherAddActions: [],
      deleteRefresh: false,
      recurrence_settings: {
        recurring_execution_value: "1",
        recurring_period: "days",
        execution_time: "05:00",
        execution_date: this.calculateDate(),
        zone: "Asia/Kolkata",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
    };
  },
  mounted() {
    if (this.currentEntity?.templates) {
      this.getAllDateFields();
      // this.allFields = this.getAllFieldsFromEntity(this.currentEntity, true);
    }
    if (this.currentEntity?._id) {
      this.getNecessaryInfo();
    }
    this.checkMonths();
    this.checkHours();
    this.checkDays();
    this.checkYears();
    this.getTimeZone();
  },
  computed: {
    ...mapGetters("entities", [
      "getEntityDataById",
      "getAllEntities",
      "getEntityVariableFieldsInputTypes",
      "getEntitiesDataErrors",
    ]),
    ...mapGetters("entityRelationships", ["getEntityRelationships"]),
    ...mapGetters("filters", ["getAllEntityFilters", "getFiltersList"]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("automationPipeline", [
      "getEntityActions",
      "getDeletePipelineStatus",
      "getUpdatePipelineStatus",
    ]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("fileManagement", ["getAllFiles"]),
    ...mapGetters("auth", [
      "getUserTypeList",
      "getActiveWorkspace",
      "getUserTypeEmailsErrors",
      "getUserTypeEmails",
      "getAuthenticatedUser",
      "getCompanyUsers",
    ]),
    ...mapGetters("contacts", ["getAllContacts"]),
    checkFormDisabled() {
      if (this.form?.when && this.form.where) {
        return false;
      }
      return true;
    },
    currentEntityTemplates() {
      return (this.currentEntity.templates || []).flatMap(
        (e) => e.templateInfo
      );
    },
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
  },
  methods: {
    handleEnterKey() {
      this.handleName();
    },
    toBeEstablishedRelationships() {
      this.otherAddActions = [];
      this.otherAddActions = this.mapOtherAddEntities(
        this.form.then,
        this.otherAddActions
      );
    },
    mapOtherAddEntities(form, otherAddActions) {
      let otherAdd = [];
      form.map((e) => {
        if (e.type == "ACTION" && e.action == "other/ADD") {
          otherAdd.push(e.keyIndex);
        } else if (e.type == "CONDITION") {
          if (e.then.length) {
            otherAddActions = this.mapOtherAddEntities(e.then, otherAddActions);
          }
          if (e.else.length) {
            otherAddActions = this.mapOtherAddEntities(e.else, otherAddActions);
          }
        }
      });
      if (otherAdd.length > 1) {
        otherAddActions.push(otherAdd);
      }
      return otherAddActions;
    },
    nameActionDialog() {
      this.nameDialog = false;
    },
    setScheduleData(schedule_data_id) {
      this.schedule_data = this.date_time_fields.find(
        (item) => item.id === schedule_data_id
      ).data;
    },
    saveAndBack() {
      if (this.isEdit) {
        if (!this.form?.name?.trim()) {
          this.nameDialog = true;
          return this.$message.warning("Please Enter Name");
        }
        let check = this.validateActions(this.form.then || []);
        let checkSettings;
        if (this.form.when == "DATE_TIME" || this.form.when == "DATE") {
          let settings =
            this.schedule_type == "date_field"
              ? this.schedule_settings
              : this.manual_schedule_settings;
          checkSettings = this.validateScheduleSettings(
            this.form,
            this.schedule_type,
            settings,
            this.schedule_data
          );
          if (!checkSettings.status) {
            return this.$message({
              message:
                checkSettings.message ||
                "Warning, Please config required settings.",
              type: "warning",
            });
          }
        } else if (this.form.when == "AUTOMATION") {
          this.form["recurrence_settings"] = this.recurrence_settings;
        }
        if (check && this.isEdit && this.currentEntity?._id) {
          if (checkSettings) {
            this.form["is_scheduled"] = true;
            this.form["schedule_type"] = this.schedule_type;
            this.form["schedule_settings"] =
              this.schedule_type == "date_field"
                ? this.schedule_settings
                : this.manual_schedule_settings;
            this.form["schedule_settings"]["time_zone"] =
              this.selected_time_zone;
            if (this.schedule_type == "date_field") {
              this.form["schedule_settings"]["schedule_period"] =
                this.selected_period;
              this.form["schedule_data"] = this.schedule_data;
            }
          }
          this.form.otherAddActions = this.otherAddActions;
          this.savePipeline({
            ...this.form,
            ...{ entity_id: this.currentEntity?._id },
          });
          this.goBack();
        } else {
          this.$message({
            message: "Warning, Please config required fields.",
            type: "warning",
          });
        }
      } else {
        let query = this.getNavigationQuery(this.$route.query);
        this.$router.push({
          path: `/entity`,
          query: query,
        });
      }
    },
    getNavigationQuery(query) {
      let q = {
        key: Math.floor(Math.random() * 100000),
      };
      query.group ? (q.group = query.group) : "";
      query.groups ? (q.groups = query.groups) : "";
      query.t ? (q.t = query.t) : "";
      query.routeType ? (q.routeType = query.routeType) : "";
      query.filter ? (q.filter = query.filter) : "";
      query.fromCustom ? (q.fromCustom = query.fromCustom) : "";
      query.menu_id ? (q.menu_id = query.menu_id) : "";
      query.dataId ? (q.dataId = query.dataId) : "";
      query.stepId ? (q.stepId = query.stepId) : "";
      query.layout ? (q.layout = query.layout) : "";
      query.viewType ? (q.viewType = query.viewType) : "";
      query.activeTab ? (q.activeTab = query.activeTab) : "";
      return q;
    },
    goBack() {
      this.isEdit = false;
      this.nameDialog = false;
    },
    async onDelete(data) {
      this.$confirm("Are you sure to delete permanently?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteAction(data);
      });
    },
    async deleteAction(data) {
      this.loading = true;
      await this.$store.dispatch("automationPipeline/deletePipeline", data._id);
      if (this.getDeletePipelineStatus) {
        this.loading = false;
        this.$notify.success({
          title: "Success",
          message: "Pipeline deleted successfully",
        });
        this.fetchEntityActions();
      } else {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: "Getting error while deleting",
        });
      }
    },
    async updateRow(row) {
      this.loading = true;
      await this.$store.dispatch("automationPipeline/updatePipeline", row);
      if (this.getUpdatePipelineStatus) {
        this.loading = false;
        this.$notify.success({
          title: "Success",
          message: "Pipeline Updated successfully",
        });
        this.fetchEntityActions();
      } else {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: "Getting error while updating",
        });
      }
    },
    async sortChange(a) {
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.fetchEntityActions();
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.fetchEntityActions();
      }
    },
    async loadData(data) {
      // console.log(data);
      if ((data && data.type != "init") || !data) {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchEntityActions();
      }
    },
    handleGlobalActionChange() {
      if(this.form?.then?.[0]) {
        this.form.then[0]["action"] = this.form.is_global_action ? "file/ADD_FOLDER" : "other/UPDATE";
        if(this.form.is_global_action) {
          this.form.then[0]["type"] = 'ACTION'
          this.form.schedule_type = "Select_manually"
        }
      }
    },
    async fetchEntityActions() {
      this.loading = true;
      let params = {
        limit: this.pageSize > 0 ? this.pageSize : 10,
        page: this.page,
        entity_id: this.currentEntity._id,
      };
      if (this.order_by) {
        params.order_by = this.order_by;
      }
      if (this.order_type) {
        params.order_type = this.order_type;
      }
      if (this.search_string) {
        params["search_string"] = this.search_string;
      }
      if (this.selectedType) {
        params["status"] = this.selectedType;
      }
      await this.$store.dispatch("automationPipeline/getEntityActions", params);
      if (this.getEntityActions) {
        this.entityActions = this.getEntityActions.data || [];
        this.total = this.getEntityActions.total || 0;
      }
      this.loading = false;
    },
    // getFieldFromAllEntities(entity_id, update_field, keyProperty, property) {
    //   if (entity_id && update_field && property) {
    //     return this.getFieldProperty(
    //       this.getTemplateFieldsFromAllEntities(entity_id),
    //       update_field,
    //       keyProperty,
    //       property
    //     );
    //   }
    //   return update_field && update_field.includes("#")
    //     ? update_field.split("#")[1]
    //     : update_field;
    // },
    getTemplateFieldsFromAllEntities(entity_id) {
      if (this.actionEntityFields[entity_id]) {
        return this.actionEntityFields[entity_id];
      }
      return [];
    },
    insertTrigger() {
      this.actions.push(JSON.parse(JSON.stringify(this.form)));
      this.resetForm();
    },
    resetForm() {
      this.form = {
        when: "ADDED",
        where: "WITHOUT_CONDITION",
        then: [
          {
            action: "other/UPDATE",
            type: "CONDITION",
            then: [],
            else: [],
            keyIndex: (Math.random() + 1).toString(36).substring(7),
          },
        ],
        name: "",
        description: "",
      };
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1); // Add 1 day to get future date
      const currentMonth = currentDate.toLocaleString("default", {
        month: "long",
      });
      const currentYear = currentDate.getFullYear();
      const currentDayOfMonth = currentDate.getDate();
      console.log("test", { currentMonth, currentYear, currentDayOfMonth });
      (this.schedule_settings = {
        executed_on_date: "On",
        executed_on_time: "On",
        execution_time: "05:00",
        recurring_order: "once",
        executed_on_date_value: "1",
        executed_on_date_period: "Day(s)",
        executed_on_time_value: "1",
        executed_on_time_period: "Hour(s)",
        recurring_execution_type: "once",
        recurring_execution_value: "5",
        recurring_period: "Day(s)",
        recurring_stopping_condition: "After",
        recurring_stopping_value: "2",
        schedule_period: "date_match",
        time_zone: "America/New_York",
        // recurring_stopping_date:''
      }),
        (this.manual_schedule_settings = {
          minutes: [0],
          hours: [12],
          days: [currentDayOfMonth],
          months: [currentMonth],
          days_of_week: [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ],
          years: [currentYear],
          checkAllminutes: false,
          checkAllhours: false,
          checkAlldays: false,
          checkAllweeks: true,
          checkAllmonths: true,
          checkAllyears: false,
          time_zone: "America/New_York",
          status: false,
          multiple: false,
        });
      this.schedule_data = "";
      this.schedule_data_id = "";
      this.schedule_type = "date_field";
      this.selected_period = "date_match";
      this.recurrence_settings = {
        recurring_execution_value: "1",
        recurring_period: "days",
        execution_time: "05:00",
        execution_date: this.calculateDate(),
        zone: "Asia/Kolkata",
      };
    },
    deleteItem(index, array) {
      if (array && array[index]) {
        array.splice(index, 1);
      }
    },
    resetStore() {
      this.$store.commit(
        "entities/setAllEntities",
        {},
        {
          root: true,
        }
      );
      this.$store.commit(
        "filters/setAllEntityFilters",
        {},
        {
          root: true,
        }
      );
    },
    async getNecessaryInfo() {
      this.loading = true;
      await Promise.all([
        this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
          entity_id: this.currentEntity._id,
        }),
        this.$store.dispatch("filters/fetchAllEntityFilters", {
          entityId: this.currentEntity._id,
        }),
        await this.$store.dispatch("fileManagement/getAllFiles", {
          entity_id: this.currentEntity._id,
        }),
        // this.$store.dispatch("entities/fetchEntities", {
        //   get_all: true,
        //   populateData: true,
        // }),
        // this.$store.dispatch("companyTemplates/getAllCompanyTemplates", {
        //   get_all: true,
        //   include_standard: true,
        //   populate_data: true,
        // }),
        this.$store.dispatch("auth/getUserTypeList", {
          companyId: this.getAuthenticatedUser.current_workspace.company_id,
          isMenu: true,
        }),
        this.$store.dispatch(
          "auth/getCompanyUsersList",
          this.getAuthenticatedUser.current_workspace.company_id
        ),
        this.$store.dispatch("contacts/fetchAllContacts", {
          get_all: true,
          type: "CONTACT",
        }),
        this.fetchEntityActions(),
      ]);
      [this.allEntities, this.allTemplates, this.allFormbuilders] =
        await Promise.all([
          this.fetchAllEntities(),
          this.fetchAllTemplates(),
          this.fetchAllFormbuilders(),
        ]);
      if (this.getAllFiles) {
        this.entityRootFolders = (this.getAllFiles.data || []).filter((e) => {
          if (
            e.type !== "FILE" &&
            e.entity_ids.find(
              (f) => f.entity_id == this.currentEntity._id && f.primary
            )
          ) {
            return true;
          }
        });
      }
      if (this.getCompanyUsers) {
        this.company_users = this.getCompanyUsers.data;
        if (
          !this.company_users
            .flatMap((e) => e._id)
            .includes(this.getAuthenticatedUser._id)
        ) {
          this.company_users.push(this.getAuthenticatedUser);
        }
      }
      if (this.getAllContacts?.data) {
        this.allContacts = this.getAllContacts.data;
      }
      //if (this.getAllCompanyTemplatesData?.data) {
      //this.allTemplates = this.getAllCompanyTemplatesData.data;
      (this.allTemplates || []).forEach((template) => {
        this.allTemplateFieldMap[template._id] = {
          name: template.name,
          id: template._id,
          fields: this.getFieldsFromTemplate(template, true, true),
        };
      });
      //}
      this.relationshipsData = [...(this.getEntityRelationships || [])];
      this.entityAllFilters = this.getAllEntityFilters;
      //this.allEntities = [...(this.getAllEntities.data || [])];
      this.resetStore();
      this.allEntities = this.allEntities.map((entity) => {
        if (entity?.templates) {
          entity.templates = entity.templates.map((template) => {
            template.templateInfo = this.allTemplates.find(
              (temp) => temp._id == template.template_id
            );
            return template;
          });
        }
        return entity;
      });
      await this.$store.dispatch("filters/fetchFiltersList", {
        entities: this.allEntities.flatMap((e) => e._id),
      });
      if (this.getFiltersList) {
        this.getFiltersList.forEach((el) => {
          if (this.allEntityFilters[el.entity_id]) {
            this.allEntityFilters[el.entity_id].push(el);
          } else {
            this.allEntityFilters[el.entity_id] = [el];
          }
        });
      }
      this.loading = false;
    },
    addNew(data) {
      if (data) {
        this.form = { ...data };
        if (!this.form.name) {
          this.form = {
            otherAddActions: [],
            establishRelationship: false,
            ...this.form,
            name: "",
            description: "",
          };
        }
        if (data.is_scheduled) {
          this.schedule_type = data.schedule_type;
          this.selected_period =
            this.form["schedule_settings"]["schedule_period"];
          this.selected_time_zone = this.form["schedule_settings"]["time_zone"];
          if (this.schedule_type == "date_field") {
            this.schedule_settings = { ...data.schedule_settings };
            this.schedule_data = { ...data.schedule_data };
            this.schedule_data_id = this.form["schedule_data"]["_id"];
          } else {
            this.manual_schedule_settings = { ...data.schedule_settings };
          }
        }
        if (data.when == "AUTOMATION" && data.recurrence_settings) {
          this.recurrence_settings = {
            ...data.recurrence_settings,
          };
        }
      } else {
        this.resetForm();
        this.nameDialog = true;
      }
      this.isEdit = true;
    },
    addNewAction(op) {
      this.form[op].push({
        action: "other/UPDATE",
        type: "CONDITION",
        then: [],
        else: [],
      });
    },
    addAction() {
      this.actions.push({
        trigger: "",
      });
    },
    handleCheckedHourChange(value) {
      //  console.log("hours",value);
      let checkedCount = value.length;
      this.manual_schedule_settings.checkAllhours =
        checkedCount === this.hours.length;
      this.ishoursIndeterminate =
        checkedCount > 0 && checkedCount < this.hours.length;
      this.manual_schedule_settings.hours = value;
    },
    handleCheckedYearChange(value) {
      // console.log("years",value);
      let checkedCount = value.length;
      this.manual_schedule_settings.checkAllyears =
        checkedCount === this.years.length;
      this.isyearsIndeterminate =
        checkedCount > 0 && checkedCount < this.years.length;
      this.manual_schedule_settings.years = value;
    },
    handleCheckYearsChange(val) {
      this.manual_schedule_settings.years = val ? yearsArray : [];
      this.isyearsIndeterminate = false;
    },
    handleCheckedMonthChange(value) {
      // console.log("months",value);
      let checkedCount = value.length;
      this.manual_schedule_settings.checkAllmonths =
        checkedCount === this.months.length;
      this.ismonthsIndeterminate =
        checkedCount > 0 && checkedCount < this.months.length;
      this.manual_schedule_settings.months = value;
    },
    handleCheckMonthsChange(val) {
      this.manual_schedule_settings.months = val ? monthsArray : [];
      this.ismonthsIndeterminate = false;
    },
    handleCheckedWeekChange(value) {
      // console.log("weeks",value);
      let checkedCount = value.length;
      this.manual_schedule_settings.checkAllweeks =
        checkedCount === this.weeks.length;
      this.isweeksIndeterminate =
        checkedCount > 0 && checkedCount < this.weeks.length;
      this.manual_schedule_settings.days_of_week = value;
    },
    handleCheckWeeksChange(val) {
      this.manual_schedule_settings.days_of_week = val ? weeksArray : [];
      this.isweeksIndeterminate = false;
    },
    handleCheckedDayChange(value) {
      // console.log("days",value);
      let checkedCount = value.length;
      this.manual_schedule_settings.checkAlldays =
        checkedCount === this.days.length;
      this.isdaysIndeterminate =
        checkedCount > 0 && checkedCount < this.days.length;
      this.manual_schedule_settings.days = value;
    },
    handleCheckDaysChange(val) {
      this.manual_schedule_settings.days = val ? daysArray : [];
      this.isdaysIndeterminate = false;
    },
    handleCheckHoursChange(val) {
      this.manual_schedule_settings.hours = val ? hoursArray : [];
      this.ishoursIndeterminate = false;
    },
    handleCheckMinutesChange(val) {
      this.manual_schedule_settings.minutes = val ? minutesArray : [];
      this.isminutesIndeterminate = false;
    },
    handleCheckedMinuteChange(value) {
      // console.log("minutes",value);
      let checkedCount = value.length;
      this.manual_schedule_settings.checkAllminutes =
        checkedCount === this.minutes.length;
      this.isminutesIndeterminate =
        checkedCount > 0 && checkedCount < this.minutes.length;
      this.manual_schedule_settings.minutes = value;
    },
    getTimeZone() {
      let date1 = new Date();
      var sign = date1.getTimezoneOffset() > 0 ? "-" : "+";
      var offset = Math.abs(date1.getTimezoneOffset());
      var hours =
        Math.floor(offset / 60) < 10
          ? "0" + Math.floor(offset / 60)
          : Math.floor(offset / 60);
      var minutes = offset % 60 < 10 ? "0" + (offset % 60) : offset % 60;
      let timezoneoffset = sign + hours + ":" + minutes;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (timezone == "Asia/Calcutta") {
        this.manual_schedule_settings.time_zone = "Asia/Kolkata";
      } else {
        this.manual_schedule_settings.time_zone = timezone;
      }
      return timezone + ", UTC" + timezoneoffset;
    },
    checkMonths() {
      if (minutesArray == 0) {
        for (let i = 0; i <= 59; i++) {
          minutesArray.push(i);
        }
      }
    },
    checkHours() {
      if (hoursArray == 0) {
        for (let i = 0; i <= 23; i++) {
          hoursArray.push(i);
        }
      }
    },
    checkDays() {
      if (daysArray == 0) {
        for (let i = 1; i <= 31; i++) {
          daysArray.push(i);
        }
      }
    },
    checkYears() {
      if (yearsArray == 0) {
        for (let i = 2023; i <= 2030; i++) {
          yearsArray.push(i);
        }
      }
    },
    async getAllDateFields() {
      this.allFields = this.getAllFieldsFromEntity(this.currentEntity, true);
      this.date_time_fields = this.allFields.filter(
        (e) => e.inputType == "DATE" || e.inputType == "DATE_TIME"
      );
      let entityVariablesAndEntityFields = [];
      this.allFields.map((e) => {
        if (e.input_type === "ENTITY_VARIABLE" || e.input_type === "ENTITY") {
          entityVariablesAndEntityFields.push(e);
        } else if (
          e.input_type == "AGGREGATE_FUNCTION" &&
          (e.selected_fields[0]?.result_type == "DATE" ||
            e.selected_fields[0]?.result_type == "DATE_TIME")
        ) {
          e.input_type = e.selected_fields[0].result_type;
          this.date_time_fields.push(e);
        } else if (
          e.input_type == "FORMULA" &&
          (e.result_type == "DATE" || e.result_type == "DATE_TIME")
        ) {
          e.input_type = e.result_type;
          this.date_time_fields.push(e);
        }
      });
      if (
        entityVariablesAndEntityFields &&
        entityVariablesAndEntityFields.length
      ) {
        await this.$store.dispatch("entities/fetchEntityFieldsInputTypes", {
          entityVariablesAndEntityFields,
        });
        if (this.getEntityVariableFieldsInputTypes) {
          await (this.getEntityVariableFieldsInputTypes || []).map((e) => {
            if (e.input_type == "DATE" || e.input_type == "DATE_TIME") {
              let field = this.allFields.find((item) => item.key == e.key);
              if (field) {
                field.input_type = e.input_type;
                this.date_time_fields.push(field);
              }
            }
          });
        }
      }
      this.date_time_fields = Array.from(new Set(this.date_time_fields));
      this.date_time_fields = this.date_time_fields.map((e) => ({
        id: e._id,
        label: e.label,
        data: e,
      }));
    },
    handleName() {
      if (!this.form.name.trim()) {
        return this.$message.warning("Please Enter Name");
      }
      return (this.nameDialog = false);
    },
    calculateDate() {
      let date = new Date();
      date = date.setDate(date.getDate() + 1);
      return date;
    },
  },
  watch: {
    saveClicked: async function () {
      console.log("Clicked ");
      this.saveAndBack();
    },
    search_string: async function () {
      this.fetchEntityActions();
    },
    form: {
      handler() {
        this.toBeEstablishedRelationships();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.bt .btn-outline-primary {
  background-color: var(--primary-color);
  color: var(--primary-contrast-color);
  border-color: var(--primary-color);
  &:hover {
    background-color: var(--primary-contrast-color);
    color: var(--primary-color);
    border-color: var(--primary-color);
  }
}
.dotted-line {
  position: relative;
  height: 100px;
}

.dotted-line::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  border-left: 1px dotted #000;
}
.main-container {
  height: 2000px !important;
  width: 2000px !important;
  .step-box {
    text-align: left !important;
    padding: 10px;
    border: 1px solid #dcdfe6;
    width: 500px !important;
  }
  .arrow-container {
    .left-label {
      position: relative;
      top: -95px !important;
      left: 50px !important;
    }
    .right-label {
      position: relative;
      top: -95px !important;
      left: -50px !important;
    }
    height: 100px;
    width: 200px;
    text-align: center !important;
    .right-arrow {
      height: 75px;
      width: 50%;
      margin-top: 25%;
      border-top: 1px solid #dcdfe6;
      border-right: 1px solid #dcdfe6;
    }
    .left-arrow {
      height: 75px;
      width: 50%;
      margin-top: 25%;
      margin-left: 50%;
      border-top: 1px solid #dcdfe6;
      border-left: 1px solid #dcdfe6;
    }
    .left-add {
      margin-top: 25%;
      margin-left: -100px;
    }
    .right-add {
      margin-top: 25%;
      margin-left: 85%;
    }
  }
}
.action-form {
  padding: 10px;
}
.no-actions {
  text-align: center;
}
.track-bar {
  text-align: center !important;
  .status-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #409eff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .status-circle p {
    font-size: 10px;
    color: white;
  }
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.activestatus {
  font-size: 14px;
  color: #6c757d !important;
}

.circle {
  position: relative;
  padding-left: 20px;
  text-transform: capitalize;
  font-size: 14px;

  &::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 50%;
  }

  &.active::after {
    background: #08ad36;
  }

  &.inactive::after {
    background: #404040;
  }

  &.deleted::after {
    background: #8b1702;
  }

  &.archived::after {
    background: #404040;
  }

  &.disable::after {
    background: #c4c4c4;
  }

  &.viewed::after {
    background: #2f80ed;
  }
}
.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.uniform-checkbox {
  flex-basis: calc(10% - 10px);
  margin: 5px;
  text-align: center;
}

.form-row {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.form-row label {
  flex-basis: 38%;
}
.action-buttons li:not(:last-child) {
  margin-right: 0px;
}
.title-class {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
[class*=" el-icon-"],
[class^="el-icon-"] {
  cursor: pointer;
  display: inline;
}
.search-input {
  margin-right: 10px;
}
.pop-up-content {
  padding: 30px 10px;
}

// .form-row el-select {
//   flex-grow: 1;
// }
</style>
